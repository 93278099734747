import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import DataTable from "components/Tables/Tables";

import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";
import CardStatsSimple from "components/Cards/CardStatsSimple";


export default function AdminDashboard() {
  const [usuarioActivos, setusuarioActivos] = useState([]);
  useEffect(() => {
    axiosModificado.get('/totalUsuariosActivos')
    .then( response => {
      setusuarioActivos(response.data);
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar los usuarios activos, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);

  const [usuariosIncapacitados, setusuariosIncapacitados ] = useState([]);
  useEffect(() => {
    axiosModificado.get('/totalUsuariosIncapacitados')
    .then( response => {
      setusuariosIncapacitados(response.data);
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar los usuarios incapacitados, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);

  const [ horasDelDia, sethorasDelDia ] = useState([]);
  useEffect(() => {
    axiosModificado.get('/totalHorasTrabajadasEnElDia')
    .then( response => {
      if( Object.keys(response.data).length === 0  ){
        sethorasDelDia(0);
      } else {
        sethorasDelDia(response.data.cantidad_horas);
      }
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar las horas del dia, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);

  const [ horasDelMes , sethorasDelMes ] = useState([]);
  useEffect(() => {
    axiosModificado.get('/totalHorasTrabajadasEnElMes')
    .then( response => {
      if( Object.keys(response.data).length === 0  ){
        sethorasDelMes(0);
      } else {
        sethorasDelMes(response.data.cantidad_horas);
      }
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar las horas del mes, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);

  const [ horasDiurnas, sethorasDiurnas ] = useState([]);
  useEffect(() => {
    axiosModificado.get('/totalHorasTrabajadasDiurnasEnElDia')
    .then( response => {
      if( Object.keys(response.data).length === 0  ){
        sethorasDiurnas(0);
      } else {
        sethorasDiurnas(response.data);
      }
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar las horas diurnas del dia, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);
  const [ horasNocturnas, sethorasNocturnas ] = useState([]);
  useEffect(() => {
    axiosModificado.get('/totalHorasTrabajadasNocturnasEnElDia')
    .then( response => {
      console.log(response.data);
      if( Object.keys(response.data).length === 0  ){
        sethorasNocturnas(0);
      } else {
        sethorasNocturnas(response.data);
      }
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar las horas nocturnas del dia, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);

  const [horarios, setHorarios] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get('/todos_horarios');
        setHorarios(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cancelar la solicitud si el componente se desmonta antes de que se complete
    };
  }, []); // Se ejecuta solo una vez al montar el componente


  const roleMapping = {
    1: 'vendedor',
    2: 'Otro Rol de prueba'
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'title',
    },
    {
      Header: 'Tienda',
      accessor: 'tienda',
    },
    {
      Header: 'start',
      accessor: 'start',
    },
    {
      Header: 'end',
      accessor: 'end',
    },
    {
      Header: 'Color',
      accessor: 'color',
      Cell: ({ cell: { value } }) => <div style={{ backgroundColor: value , width: '100%', height: '15px', borderRadius: '20px' }}></div>,
    },
    {
      Header: 'Rol',
      accessor: 'resourceId',
      Cell: ({ cell: { value } }) =>  roleMapping[value] || value    ,
    },
  ];

  if (error) return <p>Ocurrió un error: {error.message}</p>;

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
          <div className="px-4 md:px-10 mx-auto w-full">
            <div>
              {/* Card stats */}
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsSimple 
                    statSubtitle="Personal Activo"
                    statTitle={String(usuarioActivos)}
                    statIconName="far fa-user"
                    statIconColor="bg-lightBlue-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsSimple 
                    statSubtitle="Personal Incapacitado"
                    statTitle={String(usuariosIncapacitados)}
                    statIconName="far fa-user-xmark"
                    statIconColor="bg-lightBlue-300"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsSimple 
                    statSubtitle="Horas Trabajadas en el día"
                    statTitle={String(horasDelDia)}
                    statIconName="far fa-clock"
                    statIconColor="bg-orange-300"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsSimple 
                    statSubtitle="Horas Trabajadas en el mes"
                    statTitle={String(horasDelMes)}
                    statIconName="far fa-clock"
                    statIconColor="bg-orange-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsSimple 
                    statSubtitle="Horas Diurnas Hoy"
                    statTitle={String(horasDiurnas)}
                    statIconName="far fa-clock"
                    statIconColor="bg-amber-400"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStatsSimple 
                    statSubtitle="Horas Nocturnas Hoy"
                    statTitle={String(horasNocturnas)}
                    statIconName="far fa-clock"
                    statIconColor="bg-blueGray-600"
                  />
                </div>
                
                {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="NEW USERS"
                    statTitle="2,356"
                    statArrow="down"
                    statPercent="3.48"
                    statPercentColor="text-red-500"
                    statDescripiron="Since last week"
                    statIconName="fas fa-chart-pie"
                    statIconColor="bg-orange-500"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 xl:mb-0 px-4"> {/* aqui se pone cuanto mide ese bloque */}

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Horarios
                    </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <Link
                      className={
                        "bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      to="horario/crear"
                    >
                      Agregar
                    </Link>
                    
                  </div>
                </div>
              </div>



              <div className="block w-full">
                {(loading && !horarios) ? <p>Cargando...</p> : 
                  <DataTable columns={columns} data={horarios} tipo={3} />
                }
              </div>
            </div>

          </div>
        </div>


        <div className="px-4 md:px-10 mx-auto w-full">
          <Switch>
            <Route path="/admin/dashboard" />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
