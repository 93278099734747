import React, { useEffect, useState } from "react";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import DataTable from "components/Tables/Tables";

import CrearRol from "components/Formularios/CrearRol";
//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";


export default function Rol() {
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    axiosModificado.get('/todos_rol')
    .then( response => {
      setRoles(response.data);
    })
    .catch(error => {
      Swal.fire('Error', 'Error al cargar los roles, recargue la pagina.', 'error');
      console.error('Error al obtener datos de la API:', error);
    });
  }, []);
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'title',
    },
    {
      Header: 'Color',
      accessor: 'eventColor',
      Cell: ({ cell: { value } }) => <div style={{ backgroundColor: value , width: '100%', height: '15px' }}></div>,
    },
  ];
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Rol" />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-6 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
              <CrearRol />              
            </div>
          </div>
        </div>
        <div className="relative md:pt-2 pb-6 pt-2">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
              {roles.length === 0 ? (
                <p>No hay Roles</p>
              ) : (
                <DataTable columns={columns} data={roles} tipo={2} />
                
              )}         
            </div>
          </div>
        </div>



        <div className="px-4 md:px-10 mx-auto w-full ">
         
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
