import React, { useEffect, useState } from "react";
//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import DataTable from "components/Tables/Tables";
import Swal from "sweetalert2";

export default function Tiendas() {
  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_usuarios')
      .then( response => {
        setTiendas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API: [/todos_usuarios]', error);
        Swal.fire('Error en obtener Usuarios', "", "error");
      });
  }, []);

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Correo',
      accessor: 'email',
    },
    {
      Header: 'Whatsapp',
      accessor: 'whatsapp',
    },
    {
      Header: 'Telefono',
      accessor: 'telefono',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
    {
      Header: 'Tipo',
      accessor: 'tipo',
    },
  ];



  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Usuarios"/>

        <div className="relative bg-lightBlue-600 md:pt-32 pt-12 pb-10">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>

              {tiendas.length === 0 ? (
                <p>No hay tiendas</p>
              ) : (
                <DataTable columns={columns} data={tiendas} tipo={1} />
                
              )}
            </div>
          </div>
        </div>
        

        
        <div className="px-4 md:px-10 mx-auto w-full ">
          
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
