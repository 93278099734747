import React, { useEffect, useState } from "react";
//import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";
import Select from 'react-select';
import { useVerifyUser } from "helpers/verifyUser";
import './styleColaborador.css';
import SidebarColaborador from "components/Sidebar/SidebarColaborador";

export default function CalendarioColaborador() {
  const user = useVerifyUser();

  useEffect( () => {
    Swal.fire('Seleccione una tienda', '', 'info');
  } , [])

  const [horarios, setHorarios] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        
          const response = await axiosModificado.get(`/todos_horarios_usuario/${user.id_user}`);
          setHorarios(response.data);
        
      } catch (error) {
        console.error('Error fetching events: [/todos_horarios/id]', error);
      }      
    }
    fetchEvents();

  }, [user]);

  const handleSelectChange = selectedOption => {
    setSeleccionado(selectedOption);
  };

  const [roles, setRoles] = useState([]);
  useEffect(() => {
    axiosModificado.get('/todos_rol')
    .then( response => {
      setRoles(response.data);
    })
    .catch(error => {
      console.error('Error al obtener datos de la API:', error);
      Swal.fire("Error", "Error al cargar los Roles", "error");
    });
  }, []);
  const headerToolbar = {
    left: 'prev,next',
    center: 'title',
    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,today'
  }
  const calendarOptions = {
    views: {
      resourceTimelineDay: {
          slotDuration: '1:00:00',
      },
      resourceTimelineWeek: {
          slotDuration: '12:00:00',
      },
      resourceTimelineMonth: {
      },
      day: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'  }
      }
    },
    resourceAreaWidth: '150px',
    eventClick: (info) => {
      console.log( info.event.id );
      Swal.fire( info.event.title ,"en tienda: ","info");
    }
  }

  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_tiendas_for_select')
      .then( response => {
        setTiendas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      });
  }, []);
  return (
    <>
      <SidebarColaborador />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
               <h1> Bienvenido colaborador: { user.name } </h1>

              {/* <Select
                value={seleccionado}
                onChange={handleSelectChange}
                options={tiendas}
              /> */}

              <FullCalendar
                plugins={[ dayGridPlugin, resourceTimelinePlugin ]}
                initialView="resourceTimeline"
                locale = "es"
                resourceAreaHeaderContent = 'Roles'
                headerToolbar = {
                  headerToolbar
                }
                
                nowIndicator = {true}
                weekends={true}
                events={
                  horarios
                }
                resources = {roles}
                {...calendarOptions}
              />
            </div>
          </div>
        </div>
        
        
        <div className="px-4 md:px-10 mx-auto w-full">
    
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
