import React from "react";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// Formularios
import CrearUsuario from "components/Formularios/CrearUsuario";

// views

/* import Tables from "views/admin/Tables.js"; */

export default function Usuarios() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header 
        <HeaderStats /> */} 
        <CrearUsuario />
        
        <div className="px-4 md:px-10 mx-auto w-full">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
