import React, { useEffect, useState } from "react";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";


export default function EditarUsuario() {
  const {id} = useParams();
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    codigo: '',
    whatsapp: '',
    telefono: '',
    tienda: '',
    estado: '',
    tipo: '',
    fecha_nac: '',
    password: '',
  });
  const estado = ['Activo', 'Inactivo', 'Vacaciones', 'Incapacitado', 'Suspendido'];
  const tipo = ['Supervisor', 'Gerente', 'Jefe', 'Colaborador'];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get(`/usuario/${id}`);
        console.log(response.data);
        setFormData(response.data);
      } catch (error) {
        console.error('Error al obtener el item', error);
      }
    };

    fetchData();
    
  }, [id]);  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChangeTelefono = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/g, '').slice(0, 8);
    setFormData({ ...formData, [name]: formattedValue });
  };
  const handleInputChangeMail = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.trim();
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formattedValue);
    if (!isValidEmail) {
      Swal.fire("Formato invalido", "ingrese un formato de correo valido", "error");
    }
    setFormData({ ...formData, [name]: formattedValue });
  };

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    const formattedValue = formData.email.trim();
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formattedValue);
    if (!isValidEmail) {
      Swal.fire("Formato invalido", "ingrese un formato de correo valido", "error");
      return false;
    }
    try {
      const response = await axiosModificado.post('/actualizar_usuario', formData);
      if (response.data.length !== 0) {
        Swal.fire({
          title: 'Exito',
          text: 'Se actualizo correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        })
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'hubo un error al actualizar',
          icon: 'error',
          confirmButtonText: 'Intentar de nuevo'
        })
      }
      console.log('Respuesta del servidor:', response.data);
      //alert(response);
    } catch (error) {
      Swal('Error al actualizar', 'por favor, intente de nuevo', 'error');
      console.error('Error al enviar datos:', error);
    }
  };
  
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar name="Editar Usuario" />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-6 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
            <h6 className="text-blueGray-500 text-sm font-bold">Editar Usuario: {formData.name}</h6>
            <form onSubmit={handleSubmit} autoComplete="off">
              <input name="id" type="hidden" value={formData.id} />
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Nombre:
                </label>
                <input type="text" name="name" value={formData.name} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Correo:
                </label>
                <input type="text" name="email" value={formData.email} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Whatsapp:
                </label>
                <input type="tel" name="whatsapp" value={formData.whatsapp} onChange={handleInputChangeTelefono} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Codigo de emmpleado:
                </label>
                <input type="text" name="codigo" value={formData.codigo} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Telefono:
                </label>
                <input type="tel" name="telefono" value={formData.telefono} onChange={handleInputChangeTelefono} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Estado:
                </label>
                <select
                  name="estado"
                  value={formData.estado}
                  onChange={handleInputChange}
                >
                  <option value="">Selecciona un estado</option>
                  {estado.map((dept, index) => (
                    <option key={index} value={index}>
                      {dept}
                    </option>
                  ))}
                </select>
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Tipo:
                </label >
                <select
                  name="tipo"
                  value={formData.tipo}
                  onChange={handleInputChange}
                >
                  <option value="0">Selecciona un tipo</option>
                  {tipo.map((muni, index) => (
                    <option key={index} value={index}>
                      {muni}
                    </option>
                  ))}
                </select>
              </div>

              
              
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Password:
                </label >
                <input type="text" name="password" value={formData.password} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Fecha de Nacimineto:
                </label >
                <input type="date-local" name="fecha_nac" value={formData.fecha_nac} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              
              <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
            </form>
                         
            </div>
          </div>
        </div>
        <div className="relative md:pt-2 pb-6 pt-2">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
                     
            </div>
          </div>
        </div>



        <div className="px-4 md:px-10 mx-auto w-full ">
         
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
