import FooterAdmin from "components/Footers/FooterAdmin";
import Sidebar from "components/Sidebar/Sidebar";
import CrearHorarioComponent from 'components/Formularios/CrearHorario';

function CrearHorario() {
    return (
        <>
            <Sidebar/>
            <div className="relative md:ml-64 bg-blueGray-100">
                <CrearHorarioComponent/>
            <div className="px-4 md:px-10 mx-auto w-full border border-red-600">
                <FooterAdmin />
                </div>
            </div>
        </>
    );
}

export default CrearHorario;