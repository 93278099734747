import React from 'react'
import { Route , Redirect } from 'react-router-dom'
import { useVerifyUser } from 'helpers/verifyUser'

export const RouteProtectedByUser = ({component: Component, ...props }) => {
  const user = useVerifyUser();
  return (
    <Route {...props} render={(componentProps) => (
      user.name && user.tipo ? <Component {...componentProps} /> : <Redirect to="/login" />
    )} />
  );
}
