import { useUser } from "context/useUser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function useLogger(){
    const history = useHistory();
    const {setUser} = useUser();
    return (user)=>{
        setUser(user);
        localStorage.setItem("userSession", JSON.stringify(user));
        //console.log(user)
        if(user.tipo == 1){
            console.log("tipo 1")
            history.push('/colaborador/calendario');
        } else {
            console.log("tipo 2")
            history.push('/admin/dashboard');
        }
        //history.push('/calendario');    
    }
}