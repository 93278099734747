import React, { useEffect, useState } from "react";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";
import Select from 'react-select';

export default function EditHorarios() {
  // DE OBTIENE EL PARAMETRO
  const {id} = useParams();
  // SE DECLARA TODO LO QUE TIENE EL FORMULARIO
  const [formData, setFormData] = useState({
    id: '',
    rol: '',
    user_id: '',
    tienda_id: '',
    start_date: '',
    start_time: '',
    end_time: '',
    rest_start: '',
    rest_end: '',
  });
  // SE OBTIENEN LOS DATOS DE ESE HORARIO
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get(`/horario/v2/${id}`);
        setFormData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error al obtener el item', error);
      }
    };

    fetchData();
    
  }, [id]);  

  // states para los select (se cambian en el input)
  const [selectedOptionUsuario, setSelectedOptionUsuario] = useState(null);
  const [selectedOptionTienda, setSelectedOptionTienda] = useState(null);
  const [selectedOptionRol, setSelectedOptionRol] = useState(null);

  const [usuarios, setUsuarios] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_usuario_for_select')
      .then( response => {
        setUsuarios(response.data);
        const opcionPreseleccionadaUsuario = response.data.find((option) => {
          if( option.value == formData.user_id ){
            setSelectedOptionUsuario({ value: option.value , label: option.label });
          } 
        });
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      }); 
  }, [formData.user_id]);
  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_tiendas_for_select')
      .then( response => {
        setTiendas(response.data);
        const opcionPreseleccionadaTiendas = response.data.find((option) => {
          if( option.value == formData.tienda_id ){
            setSelectedOptionTienda({ value: option.value , label: option.label });
          } 
        });
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      });
  }, [formData.tienda_id]);
  const [roles, setRol] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get(`/todos_rol_for_select`);
        setRol(response.data);
        console.log("esta es la informacion para el react select")
        console.log(response.data);
        /* const objetoBuscado = response.data.find(objeto => objeto.value === formData.rol);
        setSelectedOptionRol(objetoBuscado); */
        const opcionPreseleccionadaRol = response.data.find((option) => {
          if( option.value == formData.rol ){
            setSelectedOptionRol({ value: option.value , label: option.label });
          } 
        });
        //const opcionPreseleccionada = options.find(option => option.value === formData.rol);
        
      } catch (error) {
        console.error('Error al obtener el item', error);
      }
    };

    fetchData();
    
  }, [formData.rol]);
  
  // SE CAMBIAN LOS DATOS DEL FORMULARIO
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // SE ENVIAN LOS DATOS DEL FORMULARIO
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    try {
      const response = await axiosModificado.post('/actualizar_horario', formData);
      if (response.data.length !== 0) {
        Swal.fire({
          title: 'Exito',
          text: 'Se actualizo correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        })
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'hubo un error al actualizar',
          icon: 'error',
          confirmButtonText: 'Intentar de nuevo'
        })
      }
      console.log('Respuesta del servidor:', response.data);
      //alert(response);
    } catch (error) {
      Swal('Error al actualizar', 'por favor, intente de nuevo', 'error');
      console.error('Error al enviar datos:', error);
    }
  };
  
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-6 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
            <h6 className="text-blueGray-500 text-sm font-bold">Editar Horario id: {id}</h6>
                  <form onSubmit={handleSubmit}>
                  <input name="id" type="hidden" value={formData.id} />
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Rol:
                      </label>
                      <Select options={roles} name='rol' value={selectedOptionRol} placeholder="Selecciona un Rol" onChange={(selectedOptionRol) => {
                        setSelectedOptionRol(selectedOptionRol);
                        console.log(selectedOptionRol)
                        setFormData({ ...formData, rol: selectedOptionRol ? selectedOptionRol.value : '' });
                      }}  />
                    </div>
                    
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Usuario:
                      </label>
                      <Select options={usuarios} name='user_id' value={selectedOptionUsuario} placeholder="Seleccione Usuario" onChange={(selectedOptionUsuario)=>{
                          setSelectedOptionUsuario(selectedOptionUsuario);
                          setFormData({ ...formData, user_id: selectedOptionUsuario ? selectedOptionUsuario.value : '' });
                      }} />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Tienda:
                      </label>
                      <Select options={tiendas} name='tienda_id' value={selectedOptionTienda} placeholder="Seleccione Tienda" onChange={(selectedOptionTienda) =>{
                        setSelectedOptionTienda(selectedOptionTienda);
                        setFormData({ ...formData, tienda_id: selectedOptionTienda ? selectedOptionTienda.value : '' });
                      }} />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Fecha:
                      </label >
                      <input type="date" name="start_date" value={formData.start_date} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Hora entrada:
                      </label >
                      <input type="time" name="start_time" value={formData.start_time} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Hora salida:
                      </label >
                      <input type="time" name="end_time" value={formData.end_time} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Descanso Inicio:
                      </label >
                      <input type="time" name="rest_start" value={formData.rest_start} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    <div className='relative w-full mb-3'>
                      <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Descanso Fin:
                      </label >
                      <input type="time" name="rest_end" value={formData.rest_end} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                    </div>
                    
                    <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
                  </form>
                         
            </div>
          </div>
        </div>
        <div className="relative md:pt-2 pb-6 pt-2">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
                     
            </div>
          </div>
        </div>



        <div className="px-4 md:px-10 mx-auto w-full">
  
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
