import React, { useEffect, useState } from "react";
//import axios from "axios";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";
import Select from 'react-select';
import { useUser } from "context/useUser";
import './styleAdministrador.css';

function formatearFecha(fechaOriginal) {
  const fecha = new Date(fechaOriginal);

  // Obtener los componentes de la fecha
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1; // Los meses en JavaScript son indexados desde 0
  const anio = fecha.getFullYear();
  const hora = fecha.toLocaleTimeString(); // Hora local en formato de hora (HH:MM:SS)
  const soloHora = fecha.getHours().toString().padStart(2, '0'); // Horas en formato HH
  const minutos = fecha.getMinutes().toString().padStart(2, '0'); 

  // Formatear la fecha
  const fechaFormateada = `${dia}/${mes}/${anio} ${hora}`;
  const soloFechaFormateada = `${dia}/${mes}/${anio}`;
  const soloHoraFormateada = `${hora}`;
  const horaFormateada = `${hora}:${minutos}`;

  const tiposFechas = {
    fecha_completa: fechaFormateada,
    fecha: soloFechaFormateada,
    hora: soloHoraFormateada,
    hora_formateada: horaFormateada
  };

  return tiposFechas;
}
function date_format(fecha){
  return fecha.getFullYear() +
      '-' + (fecha.getMonth() + 1) +
      '-' + (fecha.getDate()) +
      ' ' + (fecha.getHours()) +
      ':' + (fecha.getMinutes()) +
      ':' + (fecha.getSeconds());
}

export default function Calendario() {

  const {user} = useUser();

  useEffect( () => {
    Swal.fire('Seleccione una tienda', '', 'info');
  } , [])

  const [horarios, setHorarios] = useState([]);
  const [seleccionado, setSeleccionado] = useState(0);


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (seleccionado) {
          const response = await axiosModificado.get(`/todos_horarios/${seleccionado.value}`);
          setHorarios(response.data);
        } else {
          setHorarios([]);
        }
      } catch (error) {
        console.error('Error fetching events: [/todos_horarios/id]', error);
      }      
    }
    fetchEvents();
    /* axiosModificado.get(`/todos_horarios/1`)
      .then( response => {
        setHorarios(response.data);
        console.table(response.data)
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
        Swal.fire("Error", "Error al cargar los horarios", "error");
      }); */
  }, [seleccionado]);

  const handleSelectChange = selectedOption => {
    setSeleccionado(selectedOption);
  };


  const [roles, setRoles] = useState([]);
  useEffect(() => {
    axiosModificado.get('/todos_rol')
    .then( response => {
      setRoles(response.data);
    })
    .catch(error => {
      console.error('Error al obtener datos de la API:', error);
      Swal.fire("Error", "Error al cargar los Roles", "error");
    });
  }, []);
  const headerToolbar = {
    left: 'prev,next',
    center: 'title',
    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,today'
  }
  const calendarOptions = {
    views: {
      resourceTimelineDay: {
          slotDuration: '1:00:00',
      },
      resourceTimelineWeek: {
          slotDuration: '12:00:00',
      },
      resourceTimelineMonth: {
      },
      day: {
          titleFormat: { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long'  }
      }
    },
    editable: true,
    resourceAreaWidth: '150px',
    eventClick: (info) => {
      console.log( info.event.id );
      console.table( info.event );

      const fechaFormateada_start = formatearFecha(info.event.startStr);
      const fechaFormateada_end = formatearFecha(info.event.endStr);
      const fechaFormateada_start_rest = formatearFecha(info.event.extendedProps.rest_start);
      const fechaFormateada_end_rest = formatearFecha(info.event.extendedProps.rest_end);


      Swal.fire({
        title: info.event.title,
        icon: "info",
        html: `<p><b>Tienda:</b> ${info.event.extendedProps.tienda} </p>
        <p><b>Rol:</b> ${info.event.extendedProps.rol} </p>
        <p><b>Fecha:</b> ${fechaFormateada_start.fecha} </p>
        <p><b>Inicia Turno:</b> ${fechaFormateada_start.hora_formateada} </p>
        <p><b>Inicia Descanso:</b> ${fechaFormateada_start_rest.hora_formateada} </p>
        <p><b>Finaliza Descanso:</b> ${fechaFormateada_end_rest.hora_formateada} </p>
        <p><b>Finaliza Turno:</b> ${fechaFormateada_end.hora_formateada} </p>
        `
      });
      //Swal.fire( info.event.title ,"en tienda: " + info.event.extendedProps.tienda ,"info");
    },
    eventResize: (info) => {
      var resId = info.event.getResources();
      var dateStart = date_format(info.event.start);
      var dateEnd = date_format(info.event.end);

      alert( info.event.id )
      axiosModificado.post('/update_resize',{  })
        .then( response => {
          setRoles(response.data);
        })
        .catch(error => {
          console.error('Error al obtener datos de la API:', error);
          Swal.fire("Error", "Error al cargar los Roles", "error");
        });
    },
    eventDrop: (info) => {

    }
  }
  const resourceTimelineDay = {
    slotDuration: '1:00:00'
  }
  const resourceTimelineWeek = {
    slotDuration: '12:00:00'
  }
  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_tiendas_for_select')
      .then( response => {
        setTiendas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      });
  }, []);
  const handleEventResize = (info) => {
    alert( info.event.id );
  }
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Calendario" />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
               {/* <p> usuario logeado: { user.name } </p> */}

              <Select
                value={seleccionado}
                onChange={handleSelectChange}
                options={tiendas}
              />

              <FullCalendar
                plugins={[ dayGridPlugin, resourceTimelinePlugin ]}
                initialView="resourceTimeline"
                locale = "es"
                resourceAreaHeaderContent = 'Roles'
                headerToolbar = {
                  headerToolbar
                }
                
                nowIndicator = {true}
                weekends={true}
                events={
                  horarios
                }
                editable={true}
                resources = {roles}
                {...calendarOptions}
              />
            </div>
          </div>
        </div>
    
        
        <div className="px-4 md:px-10 mx-auto w-full border border-red-600">
        
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
