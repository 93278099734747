import React, { useEffect, useState } from "react";
//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views
import DataTable from "components/Tables/Tables";
import Swal from "sweetalert2";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import ExportExcel from "components/ExportExcel";


export default function ProfileColaborador() {


  

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Perfil"/>

        <div className="relative bg-lightBlue-600 md:pt-32 pt-12 pb-10">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>

              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Usuario
                    </h3>
                  </div>
                  
                  
                </div>
              </div>

             
            </div>
          </div>
        </div>
        
        <div className="px-4 md:px-10 mx-auto w-full">
          
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
