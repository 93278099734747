import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts
// CREAR




// LEER


// views without layouts

/* import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js"; */

import RouteProtectedByRol from 'components/RutasProtegidas/RouteProtectedByRol';
import Login from "views/auth/Login";
import { RouteProtectedByUser } from 'components/RutasProtegidas/RouteProtectedByUser';
import Register from 'views/auth/Register';
import Auth from "layouts/Auth.js";

import { ROLES } from 'consts/rolesUser';
import { protectedAdminRoutes } from 'consts/routesPages';
import { protectedCollaboratorRoutes } from 'consts/routesPages';
import { protectedUserRoutes } from 'consts/routesPages';

/* import Profile from "views/Profile"; */
/* import Profile from "views/Profile";
 */


export const App = () => {


  return (
    <BrowserRouter>
    <Switch>
      {/* RUTAS PROTEGIDAS POR ADMINISTRADOR */}

      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/auth" component={Auth} />

      {
        protectedAdminRoutes.map((route)=>(
          <RouteProtectedByRol 
              key={route.path} 
              role={ROLES.ADMIN} 
              path={route.path} 
              component={route.component} />
        ))
      }

      {
        protectedCollaboratorRoutes.map((route)=>(
          <RouteProtectedByRol 
              key={route.path} 
              role={ROLES.COLLABORATOR} 
              path={route.path} 
              component={route.component} />
        ))
      }

      {
        protectedUserRoutes.map((route)=>(
          <RouteProtectedByUser 
              key={route.path} 
              path={route.path} 
              component={route.component} />
        ))
      }

    {/*   
      <Route path="/landing" exact component={Landing} /> 
      <Route path="/profile" exact component={Profile} />   */}

      <Route path="/" exact component={Login} />
      <Redirect from="*" to="/" />

    </Switch>
  </BrowserRouter>
  )
}
