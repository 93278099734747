import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import axiosModificado from 'components/ConfiguracionAxios';
import Swal from 'sweetalert2';

function CrearRol() {
  const [formData, setFormData] = useState({
    nombre: '',
    color: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    try {
      const response = await axiosModificado.post('/agregar_rol', formData);
      console.log('Respuesta del servidor:', response.data);
      Swal.fire("Guardado correctamente", "", "success");
      //alert(response);
    } catch (error) {
      console.error('Error al enviar datos:', error);
      Swal.fire("Error al guardar", "", "error");
    }
  };

  return (
    <>
      <h6 className="text-blueGray-500 text-sm font-bold">Crear Rol</h6>
      <form onSubmit={handleSubmit}>
        <div className='flex'>

          <div className='w-1/2 mb-3 p-3'>
            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Nombre:
            </label>
            <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
          </div>
          <div className='w-1/2 mb-3 p-3'>
            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
              Color:
            </label>
            <input type="color" name="color" value={formData.color} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 h-11 " />
          </div>
        </div>
        
        <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
      </form>
    </>
  );
}

export default CrearRol;
