import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import DataTable from "components/Tables/Tables";

import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";

export default function Horarios() {

  const [horarios, setHorarios] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get('/todos_horarios');
        setHorarios(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup function
    return () => {
      // Cancelar la solicitud si el componente se desmonta antes de que se complete
    };
  }, []); // Se ejecuta solo una vez al montar el componente


  const roleMapping = {
    1: 'vendedor',
    2: 'Otro Rol de prueba'
  };

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'title',
    },
    {
      Header: 'Tienda',
      accessor: 'tienda',
    },
    {
      Header: 'start',
      accessor: 'start',
    },
    {
      Header: 'end',
      accessor: 'end',
    },
    {
      Header: 'Color',
      accessor: 'color',
      Cell: ({ cell: { value } }) => <div style={{ backgroundColor: value , width: '100%', height: '15px', borderRadius: '20px' }}></div>,
    },
    {
      Header: 'Rol',
      accessor: 'resourceId',
      Cell: ({ cell: { value } }) =>  roleMapping[value] || value    ,
    },
  ];

  if (error) return <p>Ocurrió un error: {error.message}</p>;

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar nombre="Horarios" />
        <div className="relative bg-lightBlue-600 md:pt-16 pb-16 pt-6">
          <div className="px-4 md:px-10 mx-auto w-full">
            
          </div>
        </div>
        <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 xl:mb-0 px-4"> {/* aqui se pone cuanto mide ese bloque */}

            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              
              <div className="rounded-t mb-0 px-4 py-3 border-0">
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Horarios
                    </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <Link
                      className={
                        "bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      to="horario/crear"
                    >
                      Agregar
                    </Link>
                    
                  </div>
                </div>
              </div>



              <div className="block w-full">
                {(loading && !horarios) ? <p>Cargando...</p> : 
                  <DataTable columns={columns} data={horarios} tipo={3} />
                }
              </div>
            </div>

          </div>
        </div>


        <div className="px-4 md:px-10 mx-auto w-full">
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
