import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExportExcel = ({ data, fileName }) => {

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <button onClick={exportToExcel}>
      Exportar a Excel
    </button>
  );
};

export default ExportExcel;
