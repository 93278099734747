import React, { useEffect, useState } from "react";
// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import { useParams , Link } from 'react-router-dom/cjs/react-router-dom.min';

//import axios from "axios";
import axiosModificado from "components/ConfiguracionAxios";
import Swal from "sweetalert2";

export default function EditarRol() {
  const {id} = useParams();
  const [formData, setFormData] = useState({
    nombre: '',
    color: '' ,
    id: ''
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosModificado.get(`/rol/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error('Error al obtener el item', error);
      }
    };

    fetchData();
    
  }, [id]);  
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Datos enviados:', formData);
    try {
      const response = await axiosModificado.post('/actualizar_rol', formData);
      if (response.data.length !== 0) {
        Swal.fire({
          title: 'Exito',
          text: 'Se actualizo correctamente',
          icon: 'success',
          confirmButtonText: 'Aceptar'
        }).then(( result ) => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'hubo un error al actualizar',
          icon: 'error',
          confirmButtonText: 'Intentar de nuevo'
        })
      }
      console.log('Respuesta del servidor:', response.data);
      //alert(response);
    } catch (error) {
      Swal('Error al actualizar', 'por favor, intente de nuevo', 'error');
      console.error('Error al enviar datos:', error);
    }
  };
  
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar name="Editar Rol" />
        <div className="relative bg-lightBlue-600 md:pt-32 pb-6 pt-12">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
                <div className="flex flex-wrap items-center">
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 className="font-semibold text-base text-blueGray-700">
                      Editar Rol: {formData.nombre}
                    </h3>
                  </div>
                  <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                    <Link
                      className={
                        "bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      }
                      to="/admin/rol"
                    >
                      Regresar
                    </Link>
                    
                  </div>
                </div>
            {/* <h6 className="text-blueGray-500 text-sm font-bold">Editar Rol: {formData.nombre}</h6> */}
              <form onSubmit={handleSubmit}>
                <div className='flex'>
                  <input name="id" type="hidden" value={formData.id} />

                  <div className='w-1/2 mb-3 p-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Nombre:
                    </label>
                    <input type="text" name="nombre" value={formData.nombre} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
                  </div>
                  <div className='w-1/2 mb-3 p-3'>
                    <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Color:
                    </label>
                    <input type="color" name="color" value={formData.color} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 h-11 " />
                  </div>
                </div>
                
                <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
              </form>
                         
            </div>
          </div>
        </div>
        <div className="relative md:pt-2 pb-6 pt-2">
          <div className='px-4 md:px-10 mx-auto w-full'>
            <div className='p-4 bg-white rounded'>
                     
            </div>
          </div>
        </div>



        <div className="px-4 md:px-10 mx-auto w-full">
  
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
