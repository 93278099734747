import { useUser } from "context/useUser";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function useLogout(){
    const history = useHistory();
    const {setUser} = useUser();
    return (user)=>{
        setUser({name : '', tipo : '' , id_user : '' });
        localStorage.setItem("userSession", JSON.stringify({name : '', tipo : '' , id_user : '' }));
        history.push('/');
    }
}