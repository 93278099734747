import { useVerifyUser } from "helpers/verifyUser";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";

function RouteProtectedByRol({component: Component, role, ...props }) {
    const user = useVerifyUser();
    return (
      <Route {...props} render={(componentProps) => (
        user && user.tipo === role ? <Component {...componentProps} /> : <Redirect to="/calendario" />
      )} />
    );
}

export default RouteProtectedByRol;